import { GoogleSignInButton } from "../components/buttons/GoogleButton";
import { Card } from "antd";
import { useAuth } from "../components/contexts/firebaseContext";

const LoginPage = () => {
  const { signInWithGoogleAccount } = useAuth();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-4">
        <Card
          className="w-max p-6 shadow-lg "
          bordered={false}
          style={{ borderRadius: "8px" }}
        >
          <h2 className="text-2xl font-semibold text-center mb-4">Welcome</h2>
          <p className="text-center text-gray-600 mb-8">Please sign in</p>
          <div className="flex justify-center">
            <GoogleSignInButton
              text={"Login/Sign Up with Google"}
              handleSignIn={signInWithGoogleAccount}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
